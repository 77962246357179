


























































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapActions, mapGetters, mapMutations, MutationMethod } from 'vuex';
import VueJsonToCsv from 'vue-json-to-csv';

import GeneralTable from '@/components/fmh_components/common/GeneralTable/GeneralTable.vue';
import Loader from '@/components/fmh_components/common/Loader/Loader.vue';
import Error from '@/components/fmh_components/common/Error/Error.vue';
import CreateNewReport from '@/components/fmh_components/common/CreateNewReport/CreateNewReport.vue';
import FMHAdvancedTable from '@/components/fmh_components/common/FMHAdvancedTable/FMHAdvancedTable.vue';
import DotsMenu from '@/components/fmh_components/common/DotsMenu/DotsMenu.vue';
import ReportsIterator from '@/components/fmh_components/ReportsIterator/ReportsIterator.vue';
import ModalConfirm from '../../../wettbewerbsvergleich/src/components/common/ModalConfirm/ModalConfirm.vue';
import SnackBar from '@/components/fmh_components/common/SnackBar/SnackBar.vue';

import { mappedInterestCredit } from '@/utils/helpers/helpers';
import {
  BASE_BLACK_COLOR,
  INTEREST_CREDIT_FESTGELD,
  SNACK_BAR_DATA,
  TEXT_ACTION_BUTTONS,
} from '@/utils/constants';

import {
  headersForCSV,
  rowsForCSV,
  savePDF,
  getType,
  reportDatePdf,
  dateForCsv,
} from '@/utils/helpers';
import { CurrentReport } from '@/interfaces';

interface ReportTypesResponseData {
  data: Object;
}

interface RequiredPayload {
  id: string;
}

@Component({
  components: {
    GeneralTable,
    Loader,
    Error,
    CreateNewReport,
    VueJsonToCsv,
    FMHAdvancedTable,
    DotsMenu,
    ReportsIterator,
    ModalConfirm,
    SnackBar,
  },
  computed: {
    ...mapGetters({
      snackbarData: 'reports/getSnackbarData',
      allReports: 'reports/allReportsTagesgeld',
      updatedReportsTagesgeld: 'reports/updatedReportsTagesgeld',
      loading: 'reports/loadingReportsTagesgeld',
      error: 'reports/error',
      getDateNowTagesgeld: 'dateNow/getDateNowTagesgeld',
      report_type: 'reports/report_type',
      isAuthorized: 'auth/isAuthorized',
      uploadedReports: 'reports/getUploadedReports',
      user: 'auth/getUser',
      abortControllers: 'reports/getAbortControllers',
    }),
  },
  methods: {
    ...mapActions({
      fetchReportTypes: 'reports/fetchReportTypes',
      fetchAllReports: 'reports/fetchAllReports',
      deleteReport: 'reports/deleteReport',
      fetchCopyReport: 'reports/fetchCopyReport',
    }),
    ...mapMutations({
      setSnackBarData: 'reports/setSnackBarData',
      deleteReportSuccess: 'reports/fetchDeleteReportSuccess',
      clearTableData: 'baugeld/clearTableData',
      setDateNowFromTagesgeld: 'dateNow/setDateNowFromTagesgeld',
      clearResult: 'reports/clearResult',
      setReportType: 'reports/setReportType',
      setLoading: 'reports/loadingAllReportsTagesgeld',
      setError: 'reports/error',
      clearControllers: 'reports/clearControllers',
      clearReports: 'reports/clearReports',
    }),
  },
})
export default class Tagesgeld extends Vue {
  [x: string]: any;
  date: String = `${this.$date(new Date())}`;
  color: String = BASE_BLACK_COLOR;
  tags: String[] = [];
  selected: String[] = [];
  dialog: Boolean = false;
  isLoadingPdf: Boolean = false;
  currentObjForSaving: CurrentReport = {};
  document = {
    show: false,
    headers: [],
    rows: [],
    type: '',
  };
  allReports!: Array<Object>;
  updatedReportsTagesgeld!: Boolean;
  isAuthorized!: Boolean;
  isDeleting = false;
  snackBarData = SNACK_BAR_DATA;
  textActionButtons = TEXT_ACTION_BUTTONS;
  abortControllers!: { abort: Function }[] | null;

  fetchReportTypes!: () => Promise<ReportTypesResponseData>;
  fetchCopyReport!: (payload: RequiredPayload) => Promise<Object>;
  setError!: MutationMethod;
  setLoading!: MutationMethod;
  deleteReportSuccess!: MutationMethod;
  setSnackBarData!: MutationMethod;
  deleteReport!: Function;
  clearControllers!: MutationMethod;
  clearReports!: MutationMethod;

  @Watch('isAuthorized') fetchReports(val: boolean) {
    if (val) {
      this.setDateNowFromTagesgeld(this.date);
      this.getProduct();
    }
  }

  get styleContent() {
    const height = window.innerHeight - 310;
    return {
      'min-height': `${height}px`,
    };
  }
  get type() {
    return getType(this.$router.currentRoute.name);
  }

  get csvFileName() {
    const date = dateForCsv(this.$date, this.currentObjForSaving);
    return `${this.fileName}_${date}`;
  }

  get fileName() {
    if ((this.currentObjForSaving as any)?.title) {
      return (this.currentObjForSaving as any).title
        .replace(/[^a-zA-ZßäüöÄÖÜ0-9,.\s]/gi, ' ')
        .replace(/^\s+|\s+$/g, '')
        .replace(/\s+/g, '_');
    }
  }
  get headersCSV() {
    return headersForCSV(this.currentObjForSaving, Number(this.report_type));
  }
  get rowsCSV() {
    //@ts-ignore
    return rowsForCSV(
      this.currentObjForSaving,
      this.dataInterestCredit,
      this.$date
    );
  }
  handleChangeConfirmModal(val) {
    this.currentObjForSaving = {};
    this.dialog = val;
  }

  goWizard() {
    this.clearResult();
    this.clearTableData();
    this.$router.push('/tagesgeld/wizard');
  }

  goSingleReport(item) {
    if (
      this.$router.currentRoute.fullPath !== `tagesgeld/report/${item.slug}`
    ) {
      this.$router.push(`tagesgeld/report/${item.slug}`).catch(() => {});
    }
  }

  async getProduct() {
    try {
      const res = await this.fetchReportTypes();
      await this.setReportType(res.data);
      await this.fetchAllReports(this.report_type);
    } catch (error: any) {
      this.setError(true);
    }
  }

  setItem(item) {
    this.currentObjForSaving = item;
  }

  createPDF() {
    this.document = {
      show: true,
      headers: (this.currentObjForSaving as any).headers,
      rows: (this.currentObjForSaving as any).rows,
      type: this.report_type,
    };

    this.isLoadingPdf = true;
    const date = reportDatePdf(this.$date, this.currentObjForSaving);
    setTimeout(() => {
      const doc = document.getElementById('app');
      const elements = (doc as HTMLElement).getElementsByClassName(
        'v-dialog__content'
      );
      (elements as any).forEach((item) => item.classList.add('hidden'));
      savePDF(
        (this.$refs.document as Vue)?.$el,
        this.fileName,
        [],
        date,
        ''
      ).then(() => {
        this.isLoadingPdf = false;
        this.document.show = false;
      });
    });
  }

  openDialog() {
    if (this.user.isDemoUser && this.currentObjForSaving.is_preconfigured) {
      this.setSnackBarData({
        text: this.$t('warnings.demoUserDeletingReport'),
        show: true,
        timeout: 3000,
        dark: false,
        color: 'orange',
      });
    } else {
      this.dialog = true;
    }
  }

  async copy() {
    try {
      this.setLoading(true);
      const res = await this.fetchCopyReport(
        (this.currentObjForSaving as any).slug
      );
      this.setLoading(false);
      return this.$router.push(`/tagesgeld/wizard/${(res as any).data.slug}`);
    } catch (e) {
      this.setLoading(false);
      this.setError(true);
    }
  }

  submitEditReport() {
    if (this.user.isDemoUser && this.currentObjForSaving.is_preconfigured) {
      this.setSnackBarData({
        text: this.$t('warnings.demoUserEditingReport'),
        show: true,
        timeout: 3000,
        dark: false,
        color: 'orange',
      });
    } else {
      return this.$router.push(
        `/tagesgeld/wizard/${(this.currentObjForSaving as any).slug}`
      );
    }
  }
  async deleteCurrentReport() {
    this.isDeleting = true;
    try {
      await this.deleteReport({
        slug: (this.currentObjForSaving as any).slug,
        product_type: '1',
      });
      this.deleteReportSuccess({
        slug: (this.currentObjForSaving as any).slug,
        product_type: '1',
      });
      this.currentObjForSaving = {};
      this.isDeleting = false;
      this.dialog = false;
    } catch (e) {
      this.isDeleting = false;
      this.dialog = false;
      this.setSnackBarData(this.snackBarData);
    }
  }
  dataInterestCredit(data) {
    if (this.type === '2') {
      return mappedInterestCredit(data, INTEREST_CREDIT_FESTGELD);
    }
    return mappedInterestCredit(data);
  }
  created() {
    if (this.abortControllers) {
      this.abortControllers.forEach((item) => {
        item?.abort();
      });
      this.clearControllers();
      this.clearReports({
        reports: ['allReportsBaugeld', 'allReportsFestgeld'],
        loading: ['loadingAllReportsBaugeld', 'loadingAllReportsFestgeld'],
      });
    }
    if (this.isAuthorized) {
      if (!this.allReports.length || this.updatedReportsTagesgeld) {
        this.setDateNowFromTagesgeld(this.date);
        this.getProduct();
      }
    }
  }
}
